<template>
  <div class="home">
    <!-- Banner轮播图 -->
    <div class="banner">
      <el-carousel height="400px">
        <el-carousel-item v-for="(banner, index) in banners" :key="index">
          <img :src="banner.image" :alt="banner.title" class="banner-image">
          <div class="banner-content">
            <h2>{{ banner.title }}</h2>
            <p>{{ banner.description }}</p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 热门产品 -->
    <section class="section">
      <div class="section-header">
        <h2>热门产品</h2>
        <router-link to="/products" class="view-more">查看更多</router-link>
      </div>
      <el-row :gutter="20">
        <el-col :xs="12" :sm="8" :md="6" v-for="product in featuredProducts" :key="product.id" class="product-col">
          <product-card :product="product" />
        </el-col>
      </el-row>
    </section>

    <!-- 新品上市 -->
    <section class="section">
      <div class="section-header">
        <h2>新品上市</h2>
        <router-link to="/products" class="view-more">查看更多</router-link>
      </div>
      <el-row :gutter="20">
        <el-col :xs="12" :sm="8" :md="6" v-for="product in newProducts" :key="product.id" class="product-col">
          <product-card :product="product" />
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductCard from '@/components/product/ProductCard.vue'

export default {
  name: 'Home',
  components: {
    ProductCard
  },
  data() {
    return {
      banners: [
        {
          title: '新品上市',
          description: '探索我们的最新产品系列',
          image: 'https://via.placeholder.com/1200x400/3498db/ffffff?text=新品上市'
        },
        {
          title: '限时特惠',
          description: '精选商品限时折扣',
          image: 'https://via.placeholder.com/1200x400/e74c3c/ffffff?text=限时特惠'
        },
        {
          title: '品质保证',
          description: '所有商品品质保证，售后无忧',
          image: 'https://via.placeholder.com/1200x400/2ecc71/ffffff?text=品质保证'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('products', ['allProducts']),
    featuredProducts() {
      // 获取前4个产品作为热门产品
      return this.allProducts.slice(0, 4)
    },
    newProducts() {
      // 获取后4个产品作为新品
      return this.allProducts.slice(4, 8)
    }
  },
  created() {
    this.fetchProducts()
  },
  methods: {
    ...mapActions('products', ['fetchProducts'])
  }
}
</script>

<style scoped>
.home {
  margin-bottom: 40px;
}

.banner {
  margin-bottom: 40px;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.banner-content h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.section {
  margin-bottom: 40px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.section-header h2 {
  font-size: 24px;
  color: #333;
}

.view-more {
  color: #409EFF;
  text-decoration: none;
}

.product-col {
  margin-bottom: 20px;
}
</style> 